const localURL = "http://localhost:8080";
const serverURL = "https://api.dadamda.me";
const devServerURL = "https://api-dev.dadamda.me";
const url = serverURL;

export const googleLoginURL = `${url}/oauth2/authorization/google`;
export const kakaoLoginURL = `${url}/oauth2/authorization/kakao`;
export const GET_OTHER_SCRAP_URL = `${url}/v1/scraps/others`;
export const GET_ARTICLE_SCRAP_URL = `${url}/v1/scraps/articles`;
export const GET_PRODUCT_SCRAP_URL = `${url}/v1/scraps/products`;
export const GET_VIDEO_SCRAP_URL = `${url}/v1/scraps/videos`;
export const GET_LIST_SCRAP_URL = `${url}/v1/scraps`;
export const POST_CREATE_OTHER_SCRAP_URL = `${url}/v1/scraps`;
export const POST_CREATE_MEMO_URL = `${url}/v1/scraps/memo`;
export const DELETE_SCRAP_URL = `${url}/v1/scraps`;
export const EDIT_sCRAP_URL = `${url}/v1/scraps`;
export const GET_USER_INFORMATION_URL = `${url}/v1/user`;
export const GET_USER_PROFILE_IMAGE = `${url}/v1/user/profile`;
export const DELETE_USER_URL = `${url}/v1/user`;

export const GET_LIST_SCRAP_SEARCH_URL = `${url}/v1/scraps/search`;
export const GET_ARTICLE_SCRAP_SEARCH_URL = `${url}/v1/scraps/articles/search`;
export const GET_PRODUCT_SCRAP_SEARCH_URL = `${url}/v1/scraps/products/search`;
export const GET_VIDEO_SCRAP_SEARCH_URL = `${url}/v1/scraps/videos/search`;
export const GET_OTHER_SCRAP_SEARCH_URL = `${url}/v1/scraps/others/search`;

export const DELETE_MEMO_URL = `${url}/v1/scraps/memo`;

export const CHANNEL_SERVICE_PLUGIN_KEY =
	"fb44c0a7-9e3b-4f97-8b6f-23bca9327362";
export const SENTRY_DSN =
	"https://87314e03ce85583e1690db5896ba08a6@o4505820078014464.ingest.sentry.io/4505877203582976";
export const GOOGLE_ANALYTICS_TRACKING_ID = "UA-287960314-1";
export const YORKIE_API_KEY = "ckd53dt047aeajg75ia0";
export const AMPLITUDE_API_KEY = "35acbeb762433ec5d532c2a26049d409";

export const GET_BOARD_LIST_URL = `${url}/v1/boards`;
export const POST_CREATE_BOARD_URL = `${url}/v1/boards`;
export const DELETE_BOARD_URL = `${url}/v1/boards`;
export const FIX_BOARD_URL = `${url}/v1/boards/fixed`;
export const EDIT_BOARD_URL = `${url}/v1/boards`;
export const GET_BOARD_URL = `${url}/v1/boards`;
export const GET_BOARD_LIST_COUNT_URL = `${url}/v1/boards/count`;
export const SEARCH_BOARD_LIST_URL = `${url}/v1/boards/search`;
export const TOGGLE_BOARD_IS_SHARED_URL = `${url}/v1/boards/isShared`;
export const TOGGLE_BOARD_IS_PUBLIC_URL = `${url}/v1/boards/isPublic`;
export const GET_BOARD_IS_SHARED_URL = `${url}/v1/boards/isShared`;
export const GET_BOARD_IS_PUBLIC_URL = `${url}/v1/boards/isPublic`;
export const GET_OPEN_BOARD_CONTENTS_URL = `${url}/ov1/share/boards/contents`;
export const GET_OPEN_BOARD_TITLE_URL = `${url}/ov1/share/boards/title`;
export const GET_SHORTENED_SHARING_BOARD_URL =
	"https://site.dadamda.me/dadamda-short-url-generator";
export const COPY_OPEN_BOARD_URL = `${url}/v1/copy/boards`;

export const UPLOAD_USER_PROFILE_IMAGE_URL = `${url}/v1/user/profile/image`;
export const EDIT_USER_NICKNAME_URL = `${url}/v1/user/profile/nickname`;
export const DELETE_USER_PROFILE_IMAGE_URL = `${url}/v1/user/profile/image`;

export const GET_TRENDING_LIST_URL = `${url}/ov1/trends/boards`;
export const CHANGE_HEART_URL = `${url}/v1/trends/heart`;
export const INCREASE_TRENDING_VIEW_COUNT_URL = `${url}/ov1/trends/boards`;
export const GET_POPULAR_USERS_URL = `${url}/ov1/trends/popularUsers`;